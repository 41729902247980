<template>
  <div class="stats">
    <n-loader :loading="$var('load')" />
    <div class="container">
      <div class="wrap-title">
        <div class="title">{{ branch.title }}</div>
      </div>
      <div class="wrap-subtitle">
        <div class="back" @click="$router.push({name: 'stats'})">
          <n-icon icon="arrow-left" />
        </div>
        <div class="title">Организации</div>
      </div>
      <div v-for="items in organizations" :class="{'less-three': Object.keys(items).length < 3}" class="piles-wrapper">
        <div v-for="item in items" :key="item.id" class="pile-wrapper">
          <div class="pile" @click="$router.push({name: 'statsOrganization',
                                                  params: {id: id, depId: departmentId, organizationId: item.id}})">
            <div class="count">{{ stats[item.id]?.length || 0 }}</div>
            <div class="subtitle">сотрудника/ов</div>
          </div>
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageStats',
  data() {
    return {
      user: $app.auth.user(),
      organizations: [],
      stats: [],
      branch: {},
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    departmentId() {
      return this.$route.params.depId
    },
  },
  created() {
    this.loadBranch()
  },
  methods: {
    loadStats() {
      $api.company.getActivePeople(this.user.companyId, {
        groupBy: 'organizations',
        id: Number(this.id),
        departmentId: Number(this.departmentId),
      }).then((response) => {
        this.stats = response.data.content.users
        let data = {}
        let i = 1
        let round = 1
        $n.each(response.data.content.organizations, (e) => {
          if (i > 3) {
            i = 1
            round += 1
          }
          data = {
            ...data,
            [round]: {
              ...data[round],
              [i]: e,
            },
          }
          i += 1
        })
        this.organizations = data
        this.loadOrganizations()
      }).catch(() => {
        this.$var('load', false)
      })
    },
    loadBranch() {
      this.$var('load', true)
      $api.company.getDepartments(this.user.companyId, this.depId).then((response) => {
        this.branch = response.data.content
        this.loadStats()
      }).catch(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.stats {
  display: flex;
  flex-direction: column;
  padding-top: 29px;
  .wrap-title{
    text-align: center;
    position: fixed;
    background-color: #F5F5FA;
    left: 0;
    right: 0;
    top: calc( env(safe-area-inset-top) + 100px);
  }
  .wrap-subtitle {
    margin-top: 20px;
    position: relative;
    .back {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;

      ::v-deep {
        svg {
          path {
            fill: #000000;
          }
        }
      }
    }
  }
  .title {
    color: black;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }
  .navigation {
    margin-top: 20px;
    .item {
      .left-side {
        display: flex;
        align-items: center;
        font-weight: bold;
        .n-icon {
          margin-right: 10px;
        }
      }
    }
  }


  .piles-wrapper {
    display: flex;
    align-items: start;
    flex-shrink: 0;
    &.less-three {
      justify-content: center;
    }
    .pile-wrapper {
      margin-top: 20px;
      margin-right: 10px;
      max-width: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .pile {
        max-width: 110px;
        min-width: 100px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #8B6DEC;
        border-radius: 10px;
        padding: 5px 10px;
        .count {
          color: #FFFFFF;
          font-size: 22px;
          font-weight: 700;
        }
        .subtitle {
          color: #FFFFFF;
          font-weight: 400;
          font-size: 10px;
        }
      }
      .title {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}
</style>
